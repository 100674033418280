
import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Hope = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h2>A Journey Of Hope</h2>
     <blockquote> I beg of you, don't give up hope! There is no such thing as hopelessness.<br/> - Reb Nachman of Breslov</blockquote>
Sometimes we feel so alone that we despair, and feel abandoned. We, God forbid, give up on ourselves. Only someone who has collapsed into hopelessness could know it doesn't exist. It certainly gives up hope.<br/><br/>

Reb Nahum once said to his chassidim:
<blockquote> "If we could hang all our sorrows on pegs and were allowed to pick those we liked best, each one of us would take back their own, for all the others would be even more difficult to bear."</blockquote>
As difficult as our life may be, we have to get a grip on our confusion. We must face it and accept it in order to grow spiritually. Why do we do choose to do this? Because deep down we believe in our life. We have hope.<br/><br/>

Reb Yehudah Tzvi of Stretin used to say: <blockquote> "When you grow aware of a new way in which to serve God, you should carry it around with you secretly and don't speak it out for nine months. It's as though you were pregnant with it. Only after that time can you let others know about it as though it were a birth."</blockquote> 
It takes time to climb. When you're pregnant with a new life, you've got to take care of yourself, nurture your new way, and give it time to grow.<br/><br/>

The Kotzker Rebbe once noted: <blockquote>"When I woke up this morning, I felt like I wasn't alive. I opened my eyes, looked at my hands and found that I could use them. So I washed them and said a blessing. I then looked at my feet and saw I could walk. I walked a little. Then I made the blessing, 'Blessed are You who revives the dead,' and I knew I was alive."</blockquote>
Every day is a new blessing. We find ourselves alive, ready to begin anew. Each night as we go to sleep, we can see our life as over, ready, with God's grace, to live a new day.<br/><br/>

<blockquote>Behold, I forgive everyone who angered or offended me, or sinned against me.<br/> (Prayer before sleep)</blockquote>
Forgiving others allows us to forgive ourselves. If we want God to forgive us, we certainly ought to be ready to forgive others. Having hope to change our lives inevitably leads us to having hope that others will experience it and desire it, too.<br/><br/>

Reb Moshe, the son of the maggid of Koznitz said: <blockquote>"It says in the Torah, 'Pure olive oil beaten for the light.' Sometimes we are beaten and bruised, but only in order to glow with light."</blockquote>
We ought not minimize our suffering and struggles for they can bring us to the light.


<h3>Hope Contemplations</h3>
Cherish, Honor, Love and Respect yourself and others. The value we place on our lives allows us to unlock, build, rely on, and trust the preciousness of who we are in this world. Even with our mistakes, we can still seek to live now with value and dignity.
Remember we have the free will to choose the way we view any situation or event in our lives. Despair may touch us, but it doesn't have to become an option. In spite of the fact that we all stumble and get depressed, we can choose to hope, and not to despair.
Just as it takes a conscious effort to develop peace of mind, so too does it take a conscious effort to have hope.<br/><br/>
Hope gives a joyous outlook on life. Hope reflects spiritual commitment to the good.<br/><br/>
Sharing our hopes with friends builds closeness.<br/><br/>
If every moment can be seen as new, then there is always room for hope. We need to concentrate on our present actions and find the hope contained in them.<br/><br/>
Hope is destroyed by worry.<br/><br/>
While we cannot control the outcome of everything in our lives, we can hope to discover what God is trying to teach us in the moment. Ask yourself ... what is God trying to teach me in this moment? ... What lesson am I learning? Even if it is a difficult lesson, how will this bring about the best in me?<br/><br/>
Every moment is of value ... and that value is discovered and unlocked when we cherish each moment and see it as an opportunity for compassionate living. This helps us see eternity in the moment. Tasting that brings great reverence for life ... and with reverence in our heart touching us, we do not become a partner with sadness, despair and negativity.<br/><br/>
Hope can transform sadness into happiness ... so happiness is built on hope. Guilt, hurt and anger can be the result of not taking care of our pain in the moment. Finding ways to soothe our pain allows hope to grow.<br/><br/>
Living with Hope teaches us to face our struggles in the moment because we know that it is possible to find goodness in who we are, and in our world.<br/><br/>
By not fearing suffering we can find hope's secret ... that even in the pain, we can serve ... and all life is ultimately for the good.
Seeking approval from others, in the end, distances us from who we are. It denies God's faith in us ... that we each bring something unique to give to the world.<br/><br/>
Hope teaches us never to despair.<br/><br/>
Hope keeps us away from the trap of seeking perfection. Instead, it allows us to focus on healing our imperfections. Seeking and nourishing the hope in our Heart keeps us far from falling into discouragement ... because we know - life always has another moment, and another tomorrow.<br/>

<h3>More on Hope</h3>

Hope gives us the strength to develop a peaceful optimism towards life. Hope gives us great courage to face seemingly insurmountable obstacles. When facing the darkness, fear, and pain, Hope teaches us that we can begin to understand and accept our imperfections and make use of the tremendous potential we possess. By seeing the world through the lens of Hope, we can truly begin to honor our uniqueness. Through honoring our own uniqueness, we can then come to appreciate and honor the precious uniqueness of others.<br/><br/>

Touching on the wellspring of hope inevitably turns life into a wonder, and that wondrous realization becomes the door to unlocking the great mystery and sacredness of each of our lives. Cultivating the inner garden of our Hopes and Dreams requires a bit of practice. After all, just as a garden needs tending, so too does the garden of the Heart. Making a commitment to ourselves builds a bond of trust and hope, not only within ourselves, but for the world. If we put forth a positive, hopeful countenance to the world, then our small world can become richer with meaning and filled with the positive presence of Hope.<br/><br/>

Perhaps the deepest insight we can acquire from letting our heart run to Hope is that we are truly never alone. With that realization, we can unlock a wellspring of love and enthusiasm for life. By turning to Hope, we discover that God has given us the ability to turn our life around, and through that turning, we discover the light within.<br/><br/>

The world we live in often gives us little encouragement to touch the inner whisperings of our heart. Ironically, when our life has plunged us into the heart of darkness it is there, by God's Grace, that we discover the still small voice of hope that is crying out from deep within our heart. Once we are touched by the caress of that voice we know that crisis doesn't lead to despair. It can instead lead to understanding the specialness of our life. It is by turning to face the darkness and the pain that we begin to understand our imperfections, accept them, and then make use of the tremendous potential each of us uniquely possesses.<br/><br/>
    </Layout>
  )
}

export default Hope

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`